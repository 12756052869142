<template>
  <div class="coming">
    <div class="main">
      <img src="@/assets/imgs/coming.png">
      <div class="title">COMING SOON</div>
      <div class="text">We are preparing something amazing and exiting for you!<br/>Stay Tuned!</div>
      <div class="btn" @click="router.back()">Confirm</div>
    </div>
  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router'

  const router = useRouter()
</script>

<style lang="less" scoped>
.coming{
  min-height: 100vh;
  background: linear-gradient(180deg, #1B1B1B 53.13%, #0D0D0D 100%);
  display: flex;
  align-items: center;
  justify-content: center;

  .main{
    width: 644px;
    text-align: center;

    .title{
      color: #F8F8F8;
      font-size: 44px;
      font-family: 'DM Sans Bold';
      letter-spacing: 4px;
      margin: 32px 0 28px 0;
    }
    .text{
      color: #697377;
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 28px;
    }
    .btn{
      width: 227px;
      height: 35px;
      background: #06C8A1;
      border-radius: 10px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      cursor: pointer;
    }
  }
}
</style>
